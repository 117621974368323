<template>
  <div class="list-page" :class="{'is-fullscreen': fullscreen}">
    <div class="search-box">
      <slot name="searchBox"/>
    </div>
    <div class="tool-box" v-if="showToolBox">
      <div class="left">
        <slot name="buttonsBox" v-bind="{selectedRows}"/>
      </div>
      <div class="right" :size="10">
        <div class="tool-item" @click="reload(false)">
          <el-tooltip placement="top" :content="$t('listPage.reload')">
            <i class="el-icon-refresh-right"/>
          </el-tooltip>
        </div>
        <div class="tool-item">
          <el-dropdown @command="handleTableSizeChange">
            <el-tooltip placement="top" :content="$t('listPage.size.title')">
              <i class="el-icon-_nav"/>
            </el-tooltip>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="large">{{$t('listPage.size.large')}}</el-dropdown-item>
              <el-dropdown-item command="medium">{{$t('listPage.size.medium')}}</el-dropdown-item>
              <el-dropdown-item command="small">{{$t('listPage.size.small')}}</el-dropdown-item>
              <el-dropdown-item command="mini">{{$t('listPage.size.mini')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="tool-item" @click="fullscreen = !fullscreen">
          <el-tooltip placement="top" :content="$t('listPage.fullscreen')">
            <i :class="fullscreen ? 'el-icon-_screen-restore' : 'el-icon-_screen-full'"/>
          </el-tooltip>
        </div>
        <import-excel-button
          v-if="showImportExcelButton"
          :module-name="moduleName"
          @success="reload(true)"
          :other-params="{a:'aa'}"
        />
        <export-excel-button
          v-if="showExportExcelButton"
          :get-list-func="getListFunc"
          :query-param="queryParam"
        />
      </div>
    </div>
    <div class="table-box">
      <el-table
        v-if="showTable"
        ref="multipleTable"
        :key="size"
        :size="size"
        :data="tableList"
        v-loading="loading"
        height="100%"
        border
        :highlight-current-row="canSelect && singleSelect"
        @current-change="handleSelectionCurrentChange"
        @row-click="$emit('row-click', $event)"
        @selection-change="handleSelectionChange">
        <el-table-column
          v-if="canSelect && !singleSelect"
          type="selection"
          width="55">
        </el-table-column>
        <slot name="columns"/>
      </el-table>
      <div v-if="showTable === false" v-loading="loading">
        <template v-for="item in tableList">
          <div>{{item}}}</div>
          <hr>
        </template>
      </div>
    </div>
    <div class="page-box" v-if="showPage">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"/>
    </div>
  </div>
</template>
<script>
import ImportExcelButton from "@/components/xzd/listPage/ImportExcelButton";
import ExportExcelButton from "@/components/xzd/listPage/exportExcelButton";

export default {
  name: "XzdListPage",
  components: {ExportExcelButton, ImportExcelButton},
  props: {
    queryParam: {},// 搜索请求参数 例如{name:'a'}
    
    // 模块名称 例如 user
    moduleName: {
      type: String,
      default: '',
    },
    
    // 请求api
    getListFunc: {
      type: Function,
      require: true,
    },
    
    // 挂载完毕后是否默认自动加载数据
    autoLoad: {
      type: Boolean,
      default: true,
    },
    
    // 是否开启选择
    canSelect: {
      type: Boolean,
      default: true,
    },
    
    // 是否开启单选
    singleSelect: {
      type: Boolean,
      default: false,
    },
    
    // 是否展示工具栏
    showToolBox: {
      type: Boolean,
      default: true
    },
    
    // 是否展示按钮-ImportExcel
    showImportExcelButton: {
      type: Boolean,
      default: false
    },
    
    // 是否展示按钮-ExportExcel
    showExportExcelButton: {
      type: Boolean,
      default: false
    },
    
    // 是否展示分页
    showPage: {
      type: Boolean,
      default: true
    },
    
    // 是否展示 table
    showTable: {
      type: Boolean,
      default: true
    },
  },
  
  data() {
    return {
      loading: false, // 是否在加载
      pageNo: 1, // 第几页面
      pageSize: 10, // 每页大小
      tableList: [], // 数据
      total: 0, // 数据总条数
      selectedRows: [], // 选中的rows
      size: 'medium',
      fullscreen: false
    }
  },
  
  watch: {
    selectedRows(val) {
      this.$emit('selectedRowsChange', JSON.parse(JSON.stringify(val)))
    },
  },
  
  mounted() {
    if (this.autoLoad) {
      this.reload()
    }
  },
  
  methods: {
    reload(pageNoTo1 = true) {
      if (pageNoTo1) {
        this.pageNo = 1
      }
      this.getList()
    },
    
    getList() {
      this.selectedRows = []
      let p = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        ...this.queryParam,
      }
      
      this.loading = true
      this.$emit('resDataChange', {})
      this.getListFunc(p)
        .then((res) => {
          this.tableList = res.data.list.map((v) => {
            v.key = Math.random()
            return v
          })
          this.total = res.data.total
          this.$emit('resDataChange', res.data)
        })
        .catch(() => {
          this.list = [];
          this.total = 0;
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 500)
        })
    },
    
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getList()
    },
    
    handleSizeChange(pageSize) {
      this.pageNo = 1
      this.pageSize = pageSize
      this.getList()
    },
    
    handleSelectionChange(val) {
      this.selectedRows = val
    },
    
    handleSelectionCurrentChange(val) {
      this.selectedRows = [val]
    },
    
    handleTableSizeChange(size) {
      this.size = size
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/style/var.scss";

.list-page {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  background-color: white;
  
  .search-box {
    ::v-deep {
      .el-form-item {
        margin-bottom: 15px !important;
      }
    }
  }
  
  .tool-box {
    display: flex;
    padding: 5px 15px;
    background-color: $--table-header-background-color;
    border-top: 1px solid $--border-color-lighter;
    border-left: 1px solid $--border-color-lighter;
    border-right: 1px solid $--border-color-lighter;
    
    .left {
      margin: 5px 0;
      flex: auto;
    }
    
    .right {
      margin: 5px 0 5px auto;
      display: flex;
      align-items: center;
      
      .tool-item {
        font-size: 16px;
        padding: 5px 6px;
        border-radius: 2px;
        border: 1px solid $--border-color-light;
        line-height: 1;
        cursor: pointer;
        
        & + .tool-item {
          margin-left: 10px;
        }
      }
    }
  }
  
  .table-box {
    flex: 1;
    width: 100%;
    height: 0;
    position: relative;
    
    > .el-table {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  
  .page-box {
    margin-top: 15px;
  }
  
  &.is-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 999999;
    padding: 20px;
  }
}



</style>