module.exports = {
  button: {
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    refresh: 'Refresh',
    save: 'Save',
    search: 'Search',
    reset: 'Reset',
    saveSortAfterDrag: 'Save sort after drag',
    confirm: 'Confirm',
    cancel: 'Cancel',
    fullscreen: 'Fullscreen',
    cancelFullscreen: 'Un fullscreen',
    exportExcel: 'Export Excel',
    importExcel: 'Import Excel',
    downloadExcelTemplate: 'Download Excel Template',
  },
  placeholder: {
    input: 'input',
    select: 'select'
  },
  language: {
    zh: '中文',
    en: 'English'
  },
  message: {
    title: 'Tips',
    saveSuccess: 'Save Success',
    addSuccess: 'Add Success',
    editSuccess: 'Edit Success',
    deleteSuccess: 'Delete Success',
    copySuccess: 'Copy Success',
    operateSuccess: 'Success',
    downloadFail: 'Download Error:',
    stepOne: 'First Step',
    stepTow: 'Second Step',
    chooseExcel: 'Choose Excel',
    moduleNameNotSet: '要上报的模块名称尚未设置',
    importing: 'Importing Excel',
    getLoginUserInfoFail: 'Getting info of user error !',
    requestError: 'Request Error',
    systemError: 'System Error',
    invalidToken: 'Invalid Token',
    codeNotExists: 'Code Not Exists',
    serverError: 'Server Error',
    serverResponseError: 'Server Response Error',
    deleteNoData: 'Delete No Data',
  },
  confirm: {
    delete: 'Delete ?',
    exportExcel: 'Export Excel ?',
    deleteBatch: 'Delete ?',
  },
  configurationEditor: {
    saveComponentToLib: 'Save Component To Lib',
    lockState: 'Lock State',
    editable: 'Editable',
    preview: 'Preview',
    exitPreview: 'Exit Preview',
    locked: 'Locked',
    searchComponent: 'Search Component',
    chooseIcon: 'chooseIcon',
    pleaseInputUrl: 'pleaseInputUrl',
    uploadPic: 'uploadPic',
    onlinePic: 'onlinePic',
    putTop: 'putTop',
    putBottom: 'putBottom',
    putUp: 'putUp',
    putDown: 'putDown',
    combine: 'combine',
    combineState: 'combineState',
    unCombine: 'unCombine',
    unlock: 'unlock',
    lock: 'lock',
    delete: 'delete',
    changeToNode: 'changeToNode',
    undo: 'undo',
    redo: 'redo',
    cut: 'cut',
    copy: 'copy',
    paste: 'paste',
    animationFrame: 'animationFrame',
    addAnimationFrame: 'addAnimationFrame',
    hide: 'hide',
    show: 'show',
    unEditable: 'unEditable',
    unMovable: 'unMovable',
    disabled: 'disabled',
    layout: 'layout',
    layoutAndStyle: 'layout and style',
    node: 'node',
    alignPens: 'alignPens',
    alignFirst: 'alignFirst',
    autoLayout: 'autoLayout',
    maxWidth: 'maxWidth',
    space: 'space',
    startLayout: 'startLayout',
    autoFit: 'autoFit',
    alignLeft: 'alignLeft',
    alignRight: 'alignRight',
    alignTop: 'alignTop',
    alignBottom: 'alignBottom',
    alignMiddle: 'alignMiddle',
    alignCenter: 'alignCenter',
    horizontalBetween: 'horizontalBetween',
    verticalBetween: 'verticalBetween',
    sameSize: 'sameSize',
    formatBrush: 'formatBrush',
    pleaseInputWebsocketUrl: 'pleaseInputWebsocketUrl',
    urlAddress: 'urlAddress*',
    clientId: 'clientId',
    customClientId: 'customClientId',
    userName: 'userName',
    password: 'password',
    topics: 'topics',
    topicsPlaceholder: 'topicsPlaceholder',
    timeInterval: 'timeInterval',
    socketCbJs: 'socketCbJs',
    variableBinding: 'variableBinding',
    notJson: 'notJson',
    socketCb: 'socketCb',
    page: 'page',
    communication: 'communication',
    defaultColor: 'defaultColor',
    penBackground: 'penBackground',
    backgroundColor: 'backgroundColor',
    bkImage: 'bkImage',
    showGrid: 'showGrid',
    gridColor: 'gridColor',
    gridSize: 'gridSize',
    gridRotate: 'gridRotate',
    autoAlignGrid: 'autoAlignGrid',
    showRule: 'showRule',
    ruleColor: 'ruleColor',
    initJs: 'initJs',
    strictScope: 'strictScope',
    strictPadding: 'strictPadding',
    strictWidth: 'strictWidth',
    strictHeight: 'strictWidth',
    x: 'X',
    y: 'Y',
    startX: 'startX',
    startY: 'startY',
    endX: 'endX',
    endY: 'endY',
    animation: 'animation',
    animationType: 'animationType',
    animationDash: 'animationDash',
    animationWaterFlow: 'animationWaterFlow',
    animationWaterDotFlow: 'animationWaterDotFlow',
    animationWaterDot: 'animationWaterDot',
    animationDotSize: 'animationDotSize',
    animationColor: 'animationColor',
    animationSpan: 'animationSpan',
    animationReverse: 'animationReverse',
    animationDuration: 'animationDuration',
    animationCycle: 'animationCycle',
    animationDefaultCycle: 'animationDefaultCycle',
    nextAnimation: 'nextAnimation',
    autoPlay: 'autoPlay',
    keepAnimationState: 'keepAnimationState',
    animationLinear: 'animationLinear',
    play: 'play',
    pause: 'pause',
    stop: 'stop',
    custom: 'custom',
    animationUpDown: 'animationUpDown',
    animationLeftRight: 'animationLeftRight',
    animationHeart: 'animationHeart',
    animationRotate: 'animationRotate',
    mouseMark: 'mouseMark',
    titleContent: 'titleContent',
    titleContentPlaceholder: 'titleContentPlaceholder',
    titleContentCbJs: 'titleContentCbJs',
    addEvent: 'addEvent',
    event: 'event',
    eventType: 'eventType',
    mouseEnter: 'mouseEnter',
    mouseOut: 'mouseOut',
    mouseActive: 'mouseActive',
    mouseInactive: 'mouseInactive',
    mouseDown: 'mouseDown',
    mouseUp: 'mouseUp',
    mouseClick: 'mouseClick',
    mouseDoubleClick: 'mouseDoubleClick',
    valueUpdate: 'valueUpdate',
    openLink: 'openLink',
    changeProp: 'changeProp',
    playAnimation: 'playAnimation',
    pauseAnimation: 'pauseAnimation',
    stopAnimation: 'stopAnimation',
    playVideo: 'playVideo',
    pauseVideo: 'pauseVideo',
    stopVideo: 'stopVideo',
    runJavascript: 'runJavascript',
    runGlobalFunction: 'runGlobalFunction',
    customMessage: 'customMessage',
    linkAddress: 'linkAddress',
    openWay: 'openWay',
    target: 'target',
    propName: 'propName',
    propValue: 'propValue',
    animationTarget: 'animationTarget',
    videoTarget: 'videoTarget',
    functionParams: 'functionParams',
    functionName: 'functionName',
    messageName: 'messageName',
    messageParams: 'messageParams',
    triggerCondition: 'triggerCondition',
    none: 'none',
    comparison: 'comparison',
    alwaysTrue: 'alwaysTrue',
    alwaysFalse: 'alwaysFalse',
    customCodeCondition: 'customCodeCondition',
    condition: 'condition',
    equal: 'equal',
    notEqual: 'notEqual',
    graterThan: 'graterThan',
    graterThanOrEqual: 'graterThanOrEqual',
    lessThan: 'lessThan',
    lessThanOrEqual: 'lessThanOrEqual',
    between: 'between',
    notBetween: 'notBetween',
    belong: 'belong',
    notBelong: 'notBelong',
    textContent: 'textContent',
    isVisible: 'isVisible',
    doAction: 'doAction',
    name: 'name',
    tag: 'tag',
    tagPlaceholder: 'tagPlaceholder',
    data: 'data',
    addData: 'addData',
    editData: 'editData',
    displayName: 'displayName',
    type: 'type',
    text: 'text',
    number: 'number',
    color: 'color',
    multiText: 'multiText',
    dropDown: 'dropDown',
    switch: 'switch',
    slider: 'slider',
    placeholder: 'placeholder',
    minValue: 'minValue',
    maxValue: 'maxValue',
    step: 'step',
    precision: 'precision',
    optionList: 'optionList',
    optionValue: 'optionValue',
    optionName: 'optionName',
    displayNamePlaceholder: 'displayNamePlaceholder',
    propNamePlaceholder: 'propNamePlaceholder',
    typePlaceholder: 'typePlaceholder',
    render: 'render',
    positionAndSize: 'positionAndSize',
    width: 'width',
    height: 'height',
    ratio: 'ratio',
    borderRadius: 'borderRadius',
    rotate: 'rotate',
    paddingTop: 'paddingTop',
    paddingBottom: 'paddingBottom',
    paddingLeft: 'paddingLeft',
    paddingRight: 'paddingRight',
    progress: 'progress',
    progressColor: 'progressColor',
    verticalProgress: 'verticalProgress',
    reverseProgress: 'reverseProgress',
    flipX: 'flipX',
    flipY: 'flipY',
    input: 'input',
    state: 'state',
    style: 'style',
    lineStyle: 'lineStyle',
    lineType: 'lineType',
    lineCap: 'lineCap',
    lineJoin: 'lineJoin',
    default: 'default',
    round: 'round',
    square: 'square',
    bevel: 'bevel',
    lineGradient: 'lineGradient',
    linearGradient: 'linearGradient',
    startColor: 'startColor',
    endColor: 'endColor',
    gradientAngle: 'gradientAngle',
    hoverColor: 'hoverColor',
    activeColor: 'activeColor',
    lineWidth: 'lineWidth',
    background: 'background',
    radialGradient: 'radialGradient',
    gradientRadius: 'gradientRadius',
    hoverBackgroundColor: 'hoverBackgroundColor',
    activeBackgroundColor: 'activeBackgroundColor',
    alpha: 'alpha',
    anchorColor: 'anchorColor',
    anchorSize: 'anchorSize',
    shadowColor: 'shadowColor',
    shadowBlur: 'shadowBlur',
    shadowOffsetX: 'shadowOffsetX',
    shadowOffsetY: 'shadowOffsetY',
    textShadow: 'textShadow',
    borderColor: 'borderColor',
    borderWidth: 'borderWidth',
    startArrow: 'startArrow',
    startArrowColor: 'startArrowColor',
    startArrowSize: 'startArrowSize',
    endArrow: 'endArrow',
    endArrowColor: 'endArrowColor',
    endArrowSize: 'endArrowSize',
    autoFrom: 'autoFrom',
    autoTo: 'autoTo',
    textWord: 'textWord',
    font: 'font',
    fontSize: 'fontSize',
    textColor: 'textColor',
    hoverTextColor: 'hoverTextColor',
    activeTextColor: 'activeTextColor',
    textBackgroundColor: 'textBackgroundColor',
    italic: 'italic',
    normal: 'normal',
    bold: 'bold',
    horizontalAlign: 'horizontalAlign',
    center: 'center',
    verticalAlign: 'verticalAlign',
    lineHeight: 'lineHeight',
    textWrap: 'textWrap',
    noWrap: 'noWrap',
    preLine: 'preLine',
    breakAll: 'breakAll',
    textWidth: 'textWidth',
    textHeight: 'textHeight',
    offsetHorizontal: 'offsetHorizontal',
    offsetVertical: 'offsetVertical',
    ellipsis: 'ellipsis',
    hideText: 'hideText',
    keepDecimal: 'keepDecimal',
    image: 'image',
    video: 'video',
    backgroundImage: 'backgroundImage',
    strokeImage: 'strokeImage',
    imageWidth: 'imageWidth',
    imageHeight: 'imageHeight',
    keepImageRatio: 'keepImageRatio',
    alignWay: 'alignWay',
    top: 'top',
    bottom: 'bottom',
    left: 'left',
    right: 'right',
    leftTop: 'leftTop',
    rightTop: 'rightTop',
    leftBottom: 'leftBottom',
    rightBottom: 'rightBottom',
    imageBottom: 'imageBottom',
    icon: 'icon',
    iconColor: 'iconColor',
    iconSize: 'iconSize',
    iconRotate: 'iconRotate',
    notAllow: 'notAllow',
    disableInput: 'disableInput',
    disableRotate: 'disableRotate',
    disableScale: 'disableScale',
    disableAnchor: 'disableAnchor',
    usuallyComponent: 'usuallyComponent',
    duration: 'duration',
    offsetX: 'offsetX',
    offsetY: 'offsetY',
    scale: 'scale',
    borderRadiusPlaceholder: '< 1 ',
    lineOffset: 'lineOffset',
    alphaPlaceholder: '0 - 1',
    pureColorBackground: 'pureColorBackground',
    value: 'value',
    blank: 'new page',
    self: 'current page',
    dropdownList: 'dropdown list'
  },
  layout: {
    changePassword: 'changePassword',
    logout: 'logout',
    oldPassword: 'oldPassword',
    oldPasswordPlaceholder: 'oldPasswordPlaceholder',
    oldPasswordError: 'oldPasswordError',
    newPassword: 'newPassword',
    newPasswordPlaceholder: 'newPasswordPlaceholder',
    newPasswordError: 'newPasswordError',
    confirmPassword: 'confirmPassword',
    confirmPasswordPlaceholder: 'confirmPasswordPlaceholder',
    confirmPasswordError: 'confirmPasswordError',
    changePasswordSuccess: 'changePasswordSuccess',
    providesTechnicalSupport:'XZD Provides Technical Support',
    profile: 'profile',
    home: 'Home',
    closeLeft: 'Close Left',
    closeRight: 'Close Right',
    closeOther: 'Close Other',
    closeAll: 'Close All'
  },
  listPage: {
    reload: 'reload',
    fullscreen: 'fullscreen',
    size: {
      title: 'Size',
      large: 'large',
      medium: 'default',
      small: 'medium',
      mini: 'small'
    }
  },
  login: {
    title: 'Login Page',
    account: 'account',
    password: 'password',
    nickName: 'nickName',
    accountPlaceholder: 'accountPlaceholder',
    passwordPlaceholder: 'passwordPlaceholder',
    loginBtn:'Login Btn'
  },
  page404: {
    goHome: 'goHome'
  },
  common: {
    id: 'id',
    operate: 'operate'
  },
  company: {
    name: 'name',
    concat: 'concat',
    mobile: 'mobile',
    maxEmployeeCount: 'maxEmployeeCount',
    employeePageCount: 'employeePageCount'
  },
  componentGroup: {
    name: 'name',
    sort: 'sort',
    systemGroup: 'systemGroup'
  },
  component: {
    name: 'name',
    sort: 'sort',
    edit: 'edit',
    add: 'add',
    group: 'group',
    icon: 'icon',
    chooseIcon: 'chooseIcon',
    uploadImage: 'uploadImage',
  },
  employee: {
    company: 'company',
    account: 'account',
    password: 'password',
    name: 'name',
    realName: 'realName',
    sort: 'sort',
  },
  page: {
    name: 'name',
    edit: 'edit',
    preview: 'preview',
    back: 'back',
    fitWindow: 'fitWindow',
    fitView: 'fitView',
    normalSize: 'normalSize',
    coverImage: 'cover image',
    uploadImage: 'upload image',
    marketGroup: 'market group',
    setMarketGroup: 'set market group',
    addFromMarket: 'add from market',
    use: 'use'
  },
  resource: {
    type: 'type',
    name: 'name',
    mark: 'mark',
    enabled: 'enabled',
    disabled: 'disabled',
    sort: 'sort',
    dir: 'dir',
    menu: 'menu',
    btn: 'btn',
    func: 'func',
    link: 'link'
  },
  role: {
    name: 'name',
    description: 'description',
    sort: 'sort',
    setResource: 'setResource'
  },
  user: {
    account: 'account',
    password: 'password',
    name: 'name',
    realName: 'realName',
    sort: 'sort',
    type: 'type',
    company: 'company',
  },
  marketGroup: {
    name: 'name',
    sort: 'sort(asc)'
  }
}