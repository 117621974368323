import axios from 'axios'
import {getToken, showErrorMsg} from '@/utils/func'
import config from '@/config'
import i18n from "@/components/lang";

let getBaseUrl = () => {
  return config.apiHost
}

let logoutFunc = () => {
  //ignore
}

export function setLogoutFunc(func) {
  logoutFunc = func || logoutFunc
}

const myAxiosInstance = axios.create({
  // timeout: 6000,
})

myAxiosInstance.interceptors.request.use(
  requestConfig => {
    requestConfig.baseURL = getBaseUrl()

    let token = getToken()
    if (token) {
      requestConfig.headers['Xzd-Token'] = token;
    }

    //数据导出
    if (requestConfig.data && requestConfig.data.exportExcel == true) {
      requestConfig.responseType = 'blob'
    }

    return Promise.resolve(requestConfig);
  },
  err => {
    showErrorMsg(i18n.t('message.title'), i18n.t('message.requestError'))
    return Promise.reject(err);
  }
)

myAxiosInstance.interceptors.response.use(
  response => {

    if (response.status < 500) {
      let res = response.data;

      let code = response.headers['xzd-code'] * 1
      let msg = decodeURIComponent(response.headers['xzd-msg'] || '')
      let fileName = response.headers['xzd-file-name'] || ''

      switch (code) {
        case 200:
          if (response.config.data && fileName && response.config.data.includes('"exportExcel":true')) {
            return response
          } else {
            return response.data
          }
          break;

        case 201:
          showErrorMsg(i18n.t('message.title'), msg || i18n.t('message.systemError'))
          return Promise.reject(res)
          break;

        case 202:
          showErrorMsg(i18n.t('message.title'), i18n.t('message.invalidToken'))
          logoutFunc()
          return Promise.reject(res)
          break;

        default:
          showErrorMsg(i18n.t('message.title'), i18n.t('message.codeNotExists'))
          return Promise.reject(i18n.t('message.serverError'))
          break;
      }

    } else {
      showErrorMsg(i18n.t('message.title'), i18n.t('message.serverResponseError'))
    }
  },
  error => {
    let {response} = error
    if (response) {
      let res = response.data;
      let code = response.headers['xzd-code'] * 1
      let msg = decodeURIComponent(response.headers['xzd-msg'] || '')

      switch (code) {
        case 201:
          showErrorMsg(i18n.t('message.title'), msg || i18n.t('message.systemError'))
          return Promise.reject(res)
          break;

        case 202:
          showErrorMsg(i18n.t('message.title'), i18n.t('message.invalidToken'))
          logoutFunc()
          return Promise.reject(res)
          break;

        default:
          showErrorMsg(i18n.t('message.title'), i18n.t('message.codeNotExists'))
          return Promise.reject(i18n.t('message.serverError'))
          break;
      }
      return Promise.reject(msg)
    } else {
      showErrorMsg(i18n.t('message.title'), i18n.t('message.serverResponseError'))
      return Promise.reject(error)
    }
  }
)

export default myAxiosInstance
