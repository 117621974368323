<template>
  <el-color-picker
    show-alpha
    v-model="color"
    colorFormat="hex"
    :predefine="predefine"
    v-bind="$attrs"
  />
</template>
<script>
const predefine = ['#FF7875FF', '#FF9C6EFF', '#FFC069FF', '#FFD666FF', '#FFF566FF', '#D3F261FF', '#95DE64FF', '#5CDBD3FF', '#69C0FFFF', '#85A5FFFF', '#B37FEBFF', '#FF85C0FF', '#000000FF', '#FFFFFFFF', '#FFFFFF00']

function toHex(val) {
  let s = parseInt(val).toString(16).toUpperCase()
  return s.length === 1 ? ('0' + s) : s;
}

export default {
  name: "ColorPicker",
  
  model: {
    prop: 'value',
    event: 'change'
  },
  
  props: {
    value: String
  },
  
  data() {
    return {
      predefine: predefine
    }
  },
  
  computed: {
    color: {
      get() {
        return this.value
      },
      set(val) {
        if (val) {
          const reg = /[\d\.]+/g
          const getArr = val.match(reg)
          getArr[3] = Math.round(getArr[3] * 255)
          val = '#' + getArr.map(item => toHex(item)).join('')
        }
        this.$emit('change', val)
      }
    }
  }
}
</script>