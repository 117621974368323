module.exports = {
  button: {
    add: '新增',
    edit: '编辑',
    delete: '删除',
    refresh: '刷新',
    save: '保存',
    search: '查询',
    reset: '重置',
    saveSortAfterDrag: '保存拖拽后的顺序',
    confirm: '确定',
    cancel: '取消',
    fullscreen: '全屏',
    cancelFullscreen: '取消全屏',
    exportExcel: '导出Excel',
    importExcel: '导入Excel',
    downloadExcelTemplate: '下载Excel模板',
  },
  placeholder: {
    input: '请输入',
    select: '请选择'
  },
  language: {
    zh: '中文',
    en: 'English'
  },
  message: {
    title: '提示',
    saveSuccess: '保存成功',
    addSuccess: '添加成功',
    editSuccess: '编辑成功',
    deleteSuccess: '删除成功',
    copySuccess: '复制成功',
    operateSuccess: '操作成功',
    downloadFail: '下载出错:',
    stepOne: '第1步',
    stepTow: '第2步',
    chooseExcel: '将填写好的Excel模版选中',
    moduleNameNotSet: '要上报的模块名称尚未设置',
    importing: '导入..',
    getLoginUserInfoFail: '获取登录者信息失败!',
    requestError: '请求异常',
    systemError: '系统错误',
    invalidToken: '登录令牌失效(V2)',
    codeNotExists: 'code码不存在',
    serverError: '服务器异常',
    serverResponseError: '服务器响应异常',
    deleteNoData: '请选择要删除的数据',
  },
  confirm: {
    delete: '确定删除吗？',
    exportExcel: '确定导出搜索到的数据到Excel, 是否继续?',
    deleteBatch: '确定删除，是否继续？',
  },
  configurationEditor: {
    saveComponentToLib: '保存组件',
    lockState: '锁定状态',
    editable: '可编辑',
    preview: '预览',
    exitPreview: '退出预览',
    locked: '锁定',
    searchComponent: '搜索组件',
    chooseIcon: '选择图标',
    pleaseInputUrl: '请输入完整地址',
    uploadPic: '上传图片',
    onlinePic: '在线图片',
    putTop: '置顶',
    putBottom: '置底',
    putUp: '上一个图层',
    putDown: '下一个图层',
    combine: '组合',
    combineState: '组合为状态',
    unCombine: '取消组合',
    unlock: '解锁',
    lock: '锁定',
    delete: '删除',
    changeToNode: '变成节点',
    undo: '撤销',
    redo: '重做',
    cut: '剪切',
    copy: '复制',
    paste: '粘贴',
    animationFrame: '动画帧',
    addAnimationFrame: '新增动画帧',
    hide: '隐藏',
    show: '显示',
    unEditable: '不可编辑',
    unMovable: '不可移动',
    disabled: '已禁用',
    layout: '布局',
    layoutAndStyle: '布局和样式',
    node: '节点',
    alignPens: '对齐(参照k框)',
    alignFirst: '对齐(参照第一个节点)',
    autoLayout: '自动排版',
    maxWidth: '最大宽度',
    space: '间距',
    startLayout: '开始排版',
    autoFit: '自适应',
    alignLeft: '左对齐',
    alignRight: '右对齐',
    alignTop: '顶部对齐',
    alignBottom: '底部对齐',
    alignMiddle: '垂直居中',
    alignCenter: '水平居中',
    horizontalBetween: '水平等距分布',
    verticalBetween: '垂直等距分布',
    sameSize: '相同大小',
    formatBrush: '格式刷',
    pleaseInputWebsocketUrl: '请输入完整WebSocket地址',
    urlAddress: 'URL地址*',
    clientId: '客户端ID',
    customClientId: '自定义客户端ID',
    userName: '用户名',
    password: '密码',
    topics: '主题*',
    topicsPlaceholder: '多个主题以 , 隔开',
    timeInterval: '时间间隔',
    socketCbJs: '消息处理JavaScript',
    variableBinding: '变量绑定',
    networkConfig: '网络配置',
    notJson: 'json格式不正确',
    socketCb: '消息处理',
    page: '页面',
    communication: '通信',
    defaultColor: '默认颜色',
    penBackground: '画笔填充颜色',
    backgroundColor: '背景颜色',
    bkImage: '背景图片',
    showGrid: '显示网格',
    gridColor: '网格颜色',
    gridSize: '网格大小',
    gridRotate: '网格角度',
    autoAlignGrid: '自动对齐网格',
    showRule: '显示标尺',
    ruleColor: '标尺颜色',
    initJs: '初始化JS',
    strictScope: '严格大屏范围',
    strictPadding: '大屏拖拽边界',
    strictWidth: '大屏宽度',
    strictHeight: '大屏高度',
    x: 'X',
    y: 'Y',
    startX: '起点坐标X',
    startY: '起点坐标Y',
    endX: '终点坐标X',
    endY: '终点坐标Y',
    animation: '动画',
    animationType: '动画效果',
    animationDash: '动画线条',
    animationWaterFlow: '水流',
    animationWaterDotFlow: '水珠流动',
    animationWaterDot: '圆点',
    animationDotSize: '圆点大小',
    animationColor: '动画颜色',
    animationSpan: '动画速度',
    animationReverse: '反向流动',
    animationDuration: '动画时长',
    animationCycle: '循环次数',
    animationDefaultCycle: '默认无限循环',
    nextAnimation: '下个动画',
    autoPlay: '自动播放',
    keepAnimationState: '保持动画状态',
    animationLinear: '线性播放',
    play: '播放',
    pause: '暂停',
    stop: '停止',
    custom: '自定义',
    animationUpDown: '上下跳动',
    animationLeftRight: '左右跳动',
    animationHeart: '心跳',
    animationRotate: '旋转',
    mouseMark: '鼠标提示',
    titleContent: '标题内容',
    titleContentPlaceholder: '标题内容(Markdown)语法',
    titleContentCbJs: '标题内容函数',
    addEvent: '添加事件',
    event: '事件',
    eventType: '事件类型',
    mouseEnter: '鼠标移入',
    mouseOut: '鼠标移出',
    mouseActive: '选中',
    mouseInactive: '取消选中',
    mouseDown: '鼠标按下',
    mouseUp: '鼠标弹起',
    mouseClick: '单击',
    mouseDoubleClick: '双击',
    valueUpdate: '值变化',
    openLink: '打开链接',
    changeProp: '更改属性',
    playAnimation: '执行动画',
    pauseAnimation: '暂停动画',
    stopAnimation: '停止动画',
    playVideo: '播放视频',
    pauseVideo: '暂停视频',
    stopVideo: '停止视频',
    runJavascript: '执行JavaScript',
    runGlobalFunction: '执行全局函数',
    customMessage: '自定义消息',
    linkAddress: '链接地址',
    openWay: '打开方式',
    target: '目标',
    propName: '属性名',
    propValue: '属性值',
    animationTarget: '动画目标',
    videoTarget: '视频目标',
    functionParams: '函数参数',
    functionName: '函数名称',
    messageName: '消息名',
    messageParams: '消息参数',
    triggerCondition: '触发条件',
    none: '无',
    comparison: '关系运算',
    alwaysTrue: '总是为真',
    alwaysFalse: '总是为假',
    customCodeCondition: '自定义代码判断',
    condition: '条件',
    equal: '等于',
    notEqual: '不等于',
    graterThan: '大于',
    graterThanOrEqual: '大于等于',
    lessThan: '小于',
    lessThanOrEqual: '小于等于',
    between: '介于',
    notBetween: '不介于',
    belong: '属于',
    notBelong: '不属于',
    textContent: '文本内容',
    isVisible: '是否显示',
    doAction: '执行动作',
    name: '名称',
    tag: '标签',
    tagPlaceholder: '按回车添加，最多100个',
    data: '数据',
    addData: '添加数据',
    editData: '编辑数据',
    displayName: '显示名称',
    type: '类型',
    text: '文本',
    number: '数字',
    color: '颜色',
    multiText: '多行文本',
    dropDown: '下拉框',
    switch: '开关',
    slider: '滑块',
    placeholder: '提示文字',
    minValue: '最小值',
    maxValue: '最大值',
    step: '步长',
    precision: '精度',
    optionList: '选项列表',
    optionValue: '选项值',
    optionName: '选项名',
    displayNamePlaceholder: '请输入显示名称',
    propNamePlaceholder: '请输入属性名',
    typePlaceholder: '请选择类型',
    render: '渲染',
    positionAndSize: '位置和大小',
    width: '宽',
    height: '高',
    ratio: '锁定宽高比',
    borderRadius: '圆角',
    rotate: '旋转',
    paddingTop: '内边距-上',
    paddingBottom: '内边距-下',
    paddingLeft: '内边距-左',
    paddingRight: '内边距-右',
    progress: '进度',
    progressColor: '进度颜色',
    verticalProgress: '垂直进度',
    reverseProgress: '反向进度',
    flipX: '水平翻转',
    flipY: '垂直翻转',
    input: '输入框',
    state: '状态',
    style: '样式',
    lineStyle: '线条样式',
    lineType: '线条类型',
    lineCap: '末端样式',
    lineJoin: '连接样式',
    default: '默认',
    round: '圆形',
    square: '方形',
    bevel: '斜角',
    lineGradient: '线条渐变',
    linearGradient: '线性渐变',
    startColor: '开始颜色',
    endColor: '结束颜色',
    gradientAngle: '渐变角度',
    hoverColor: '浮动颜色',
    activeColor: '选中颜色',
    lineWidth: '线条宽度',
    background: '背景',
    radialGradient: '径向渐变',
    gradientRadius: '渐变半径',
    hoverBackgroundColor: '浮动背景颜色',
    activeBackgroundColor: '选中背景颜色',
    alpha: '透明度',
    anchorColor: '锚点颜色',
    anchorSize: '锚点大小',
    shadowColor: '阴影颜色',
    shadowBlur: '阴影模糊',
    shadowOffsetX: '阴影 X 偏移',
    shadowOffsetY: '阴影 Y 偏移',
    textShadow: '文字阴影',
    borderColor: '边框颜色',
    borderWidth: '边框宽度',
    startArrow: '起点箭头',
    startArrowColor: '起点箭头颜色',
    startArrowSize: '起点箭头大小',
    endArrow: '终点箭头',
    endArrowColor: '终点箭头颜色',
    endArrowSize: '终点箭头大小',
    autoFrom: '起点自动关联',
    autoTo: '终点自动关联',
    textWord: '文字',
    font: '字体',
    fontSize: '字体大小',
    textColor: '文字颜色',
    hoverTextColor: '浮动文字颜色',
    activeTextColor: '选中文字颜色',
    textBackgroundColor: '文字背景颜色',
    italic: '倾斜',
    normal: '正常',
    bold: '加粗',
    horizontalAlign: '水平对齐',
    center: '居中',
    verticalAlign: '垂直对齐',
    lineHeight: '行高',
    textWrap: '换行',
    noWrap: '不换行',
    preLine: '换行符换行',
    breakAll: '永远换行',
    textWidth: '文本宽度',
    textHeight: '文本高度',
    offsetHorizontal: '水平偏移',
    offsetVertical: '垂直偏移',
    ellipsis: '超出省略',
    hideText: '隐藏文字',
    keepDecimal: '保留小数',
    image: '图片',
    video: '视频',
    backgroundImage: '背景图片',
    strokeImage: '线条描绘图片',
    imageWidth: '图片宽度',
    imageHeight: '图片高度',
    keepImageRatio: '保持比例',
    alignWay: '对齐方式',
    top: '上',
    bottom: '下',
    left: '左',
    right: '右',
    leftTop: '左上',
    rightTop: '右上',
    leftBottom: '左下',
    rightBottom: '右下',
    imageBottom: '图片位于下层',
    icon: '图标',
    iconColor: '图标颜色',
    iconSize: '图标大小',
    iconRotate: '图标旋转',
    notAllow: '禁止',
    disableInput: '禁止输入',
    disableRotate: '禁止旋转',
    disableScale: '禁止缩放',
    disableAnchor: '禁止锚点',
    usuallyComponent: '常用组件',
    duration: '时长',
    offsetX: '偏移X',
    offsetY: '偏移Y',
    scale: '缩放',
    borderRadiusPlaceholder: '< 1 比例',
    lineOffset: '线条偏移',
    alphaPlaceholder: '0 - 1',
    pureColorBackground: '纯色背景',
    value: '值',
    blank: '新页面',
    self: '本页面',
    dropdownList: '下拉列表'
  },
  layout: {
    changePassword: '修改密码',
    logout: '退出登录',
    oldPassword: '原密码',
    oldPasswordPlaceholder: '请输入原密码',
    oldPasswordError: '原密码错误',
    newPassword: '新密码',
    newPasswordPlaceholder: '请输入新密码',
    newPasswordError: '新密码不能与原密码一致',
    confirmPassword: '确认密码',
    confirmPasswordPlaceholder: '请输入确认密码',
    confirmPasswordError: '两次输入密码不一致',
    changePasswordSuccess: '修改密码成功，请重新登录',
    providesTechnicalSupport:'XZD 提供技术支持',
    profile: '个人信息',
    home: '主页',
    closeLeft: '关闭左侧',
    closeRight: '关闭右侧',
    closeOther: '关闭其他',
    closeAll: '关闭所有'
  },
  listPage: {
    reload: '刷新',
    fullscreen: '全屏',
    size: {
      title: '密度',
      large: '宽松',
      medium: '默认',
      small: '中等',
      mini: '紧凑'
    }
  },
  login: {
    title: '登录',
    account: '账号',
    password: '密码',
    nickName: '昵称',
    accountPlaceholder: '请输入账号',
    passwordPlaceholder: '请输入密码',
    loginBtn:'登录'
  },
  page404: {
    goHome: '回到首页'
  },
  common: {
    id: '主键',
    operate: '操作'
  },
  company: {
    name: '名称',
    concat: '联系人',
    mobile: '联系人电话',
    maxEmployeeCount: '最大员工数',
    employeePageCount: '最大组态图数'
  },
  componentGroup: {
    name: '名称',
    sort: '排序(升序)',
    systemGroup: '系统分组'
  },
  component: {
    name: '名称',
    sort: '排序(升序)',
    edit: '编辑组件',
    add: '新增组件',
    group: '分组',
    icon: '图标',
    chooseIcon: '选择图标',
    uploadImage: '上传图片',
  },
  employee: {
    company: '所属公司',
    account: '账号',
    password: '密码',
    name: '用户名',
    realName: '真实姓名',
    sort: '排序(升序)',
  },
  page: {
    name: '名称',
    edit: '编辑图',
    preview: '预览图',
    back: '返回',
    fitWindow: '适应窗口大小',
    fitView: '适应短边大小',
    normalSize: '正常大小',
    coverImage: '封面图',
    uploadImage: '上传图片',
    marketGroup: '市场分组',
    setMarketGroup: '设置市场分组',
    addFromMarket: '从市场选取',
    use: '使用'
  },
  resource: {
    type: '类型',
    name: '资源名称',
    mark: '资源标识',
    enabled: '启用',
    disabled: '不启用',
    sort: '排序(升序)',
    dir: '菜单(含子项)',
    menu: '菜单(不含子项)',
    btn: '按钮',
    func: '功能',
    link: '超链接'
  },
  role: {
    name: '名称',
    description: '描述',
    sort: '排序(升序)',
    setResource: '设置权限'
  },
  user: {
    account: '账号',
    password: '密码',
    name: '用户名',
    realName: '真实姓名',
    sort: '排序(升序)',
    type: '用户类型',
    company: '所属公司',
  },
  marketGroup: {
    name: '名称',
    sort: '排序(升序)'
  }
}