<template>
  <span style="text-align: center">
    <el-button type="text" size="mini" @click="start">{{$t('button.importExcel')}}</el-button>
    <span>&nbsp;</span>
    <span>&nbsp;</span>
    <span>&nbsp;</span>
    <span>&nbsp;</span>
    <el-dialog
      :title="info.title||'导入Excel'"
      :visible.sync="dialogVisible"
      width="400px">
      <div v-loading="getIng" style="text-align: left">
        <h2>
          {{$t('message.stepOne')}} <el-button type="text" size="mini" @click="downloadTemplateExcel">{{$t('button.downloadExcelTemplate')}}</el-button>
        </h2>
        <br/>
        <h2>
          {{$t('message.stepTow')}} {{$t('message.chooseExcel')}} <input ref="fileInput" type="file" v-if="dialogVisible"/>
        </h2>
        <br/>
        <div style="text-align: center">
          <el-button size="mini" @click="dialogVisible = false">{{$t('button.cancel')}}</el-button>
          <el-button type="primary" size="mini" :loading="importLoading" @click="doImport">{{$t('button.confirm')}}</el-button>
        </div>
      </div>

    </el-dialog>
  </span>
</template>

<script>
import {showErrorMsg, showSuccessMsg} from "@/utils/func";

export default {
  name: "ImportExcelButton",
  props: {
    moduleName: {
      type: String,
      default: '', // 例如 user
    },
    otherParams: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },

  data() {
    return {
      dialogVisible: false,
      getIng: false,
      info: {
        title: this.$t('message.importing'),
        templateExcelPath: '',
      },
      importLoading: false
    }
  },
  methods: {
    start(moduleName) {
      if (!moduleName) {
        showErrorMsg(this.$t('message.title'), this.$t('message.moduleNameNotSet'))
        return
      }
      this.dialogVisible = true
      this.getIng = false
      this.info = {}
      this.importLoading = false
      this.getInfoBeforeImport(moduleName)
    },

    getInfoBeforeImport(moduleName) {
      // todo...
      this.getIng = true
      setTimeout(() => {

        this.info = {
          title: '导入用户',
          templateExcelPath: 'a.xlsx',
        }
        this.getIng = false

      }, 2000)
    },

    downloadTemplateExcel() {
      // todo...
      console.log('去下载模版', this.info.templateExcelPath)
    },


    doImport() {
      if (this.$refs.fileInput.files.length === 0) {
        showErrorMsg('提示', '请选择文件')
        return
      }

      this.importLoading = true

      let formData = new FormData()

      formData.append('file', this.$refs.fileInput.files[0])


      Object.keys(this.otherParams).forEach(key => {
        formData.append(key, this.otherParams[key])
      })



      // todo...
      // importRealNameList(formData).then(() => {
      //   showSuccessMsg(this.$t('message.title'), '导入成功')
      //   this.$emit('success')
      //   this.dialogVisible = false
      // }).catch(err => {
      // }).finally(() => {
      //   this.importLoading = false
      // })

      setTimeout(() => {
        showSuccessMsg(this.$t('message.title'), '导入成功')
        this.$emit('success')
        this.dialogVisible = false
        this.importLoading = false
      }, 2000)
    }
  }
}
</script>

<style scoped>

</style>