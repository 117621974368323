<script>
export default {
  name: "ShowSpace",
  
  props: {
    align: {
      type: String,
      validator: (val) => ['start', 'end', 'center', 'baseline'].includes(val)
    },
    direction: {
      type: String,
      default: 'horizontal',
      validator: (val) => ['vertical', 'horizontal'].includes(val)
    },
    size: {
      type: [String, Number],
      default: 'small',
      validator: (val) => (['small', 'middle', 'large'].includes(val) || !isNaN(parseFloat(val)))
    }
  },
  
  computed: {
    classList() {
      let list = ['show-space', 'show-space-' + this.direction]
      let align = this.align
      if (!align && this.direction === 'horizontal') {
        align = 'center'
      }
      if (align) {
        list.push('show-space-align-' + align)
      }
      return list
    },
    gap() {
      if (this.size) {
        if (this.size === 'small') {
          return 8
        } else if (this.size === 'middle') {
          return 16
        } else if (this.size === 'large') {
          return 24
        } else {
          if (!isNaN(this.size)) {
            return this.size
          }
        }
      }
      return 0
    }
  },
  
  render(h) {
    let children = this.$slots.default || []
    return (
      <div class={this.classList}>
        {
          children.map((child, index) => (
            <div class="show-space-item" style={index + 1 < children.length ? `margin-right: ${this.gap}px` : ''}>{child}</div>
          ))
        }
      </div>
    )
  }
}
</script>
<style lang="scss">
.show-space {
  display: inline-flex;
  
  &-vertical {
    flex-direction: column;
  }
  
  &-align-start {
    align-items: start;
  }
  
  &-align-end {
    align-items: end;
  }
  
  &-align-center {
    align-items: center;
  }
  
  &-align-baseline {
    align-items: baseline;
  }
}
</style>