export default {
  tokenWhiteList: [
    'Login',
    'Page404',
    'TestXysTest1',
    'TestXysTest2',
    'TestXysTest3',
    'ShowV2'
  ],
  loginPath:'/login',
  tokenKey:'token',
  // apiHost:'https://zutai-test.web.xinzhidi.cn/api',
  // apiHost:'https://zutai.web.xinzhidi.cn/api',
  apiHost:'https://zt.tengineiot.cn/api',
  // apiHost: '/api',
  appName:'云上组态',
  appEnName:'Configuration',
}